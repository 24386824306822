import { APP_CUSTOMIZATION } from "constants/dict";
import { AppName, FormatedResults, Result } from "types/app";

interface getVisibleResultsArgs {
  results: FormatedResults;
  appName: AppName;
}

export const getVisibleResults = ({
  results,
  appName,
}: getVisibleResultsArgs) => {
  let barGraphs: Result[] = [];
  let radars: Result[] = [];

  const { visibleBars, visibleRadars } = APP_CUSTOMIZATION[appName];

  visibleBars.forEach((measurement) => {
    if (measurement in results) {
      barGraphs.push(results[measurement]);
    }
  });

  visibleRadars.forEach((measurement) => {
    if (measurement in results) {
      radars.push(results[measurement]);
    }
  });

  return { barGraphs, radars };
};
