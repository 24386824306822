import { AppCustomization, BarColors, BarRates, RadarColors } from "types/dict";
import { ReactComponent as LogoDermobella } from "images/logo-dermobella.svg";
import { ReactComponent as LogoDermopico } from "images/logo-dermopico.svg";
import { ReactComponent as LogoPirreFabre } from "images/logo-pierre-fabre.svg";
import { ReactComponent as LogoMorgan } from "images/logo-morgan.svg";
import { ReactComponent as LogoLoreal } from "images/logo-loreal.svg";

export const TRANSLATION_KEYS = {
  pores: "pores",
  keratin: "keratin",
  spots: "spots",
  wrinkles: "wrinkles",
  porphyrin: "impurities",
  impurities: "impurities",
  sensitivityredness: "sensitivityredness",
  sebumT: "sebum_t",
  sebumU: "sebum_u",
  moistureT: "moisture_t_zone",
  moistureU: "moisture_u_zone",
  density: "hairdensity",
  hairloss: "hairloss",
  thickness: "thickness",
  hydration: "scalp_hydration",
  impurity: "scalp_impurities",
  deadskincells: "deadskincells",
  scalpKeratin: "deadskincells",
  scalpRedness: "sensitivity",
  scalpredness: "sensitivity",
  sebum: "scalp_sebum",
  dandruff: "dandruff",
};

export const HAIR_CONDITIONS = {
  NORMAL: 1,
  DRY: 2,
  DAMAGED: 3,
  BRITTLE: 4,
  FRIZZY: 5,
  LONG_HAIR_WITH_THINNED_END: 6,
  COLOR_TREATED: 7,
  CHEMICALLY_TREATED: 8,
};

export const SCALP_CONDITIONS = {
  NORMAL: 1,
  SENSITIVE: 2,
  OILY: 3,
  VERY_OILY: 4,
  SCALP_PRONE_TO_DRY_DANDRUFF: 5,
  SCALP_PRONE_TO_OILY_DANDRUFF: 6,
  SCALP_PRONE_TO_HAIRFALL_THINNING: 7,
};

export const BAR_RATES: BarRates = {
  very_poor: [81, 100],
  poor: [61, 80],
  moderate: [41, 60],
  good: [21, 40],
  excellent: [0, 20],
};

export const BAR_RATES_REVERSED: BarRates = {
  very_poor: [0, 20],
  poor: [21, 40],
  moderate: [41, 60],
  good: [61, 80],
  excellent: [81, 100],
};

export const RATE_COLORS: BarColors = {
  very_poor: "#ff0000",
  poor: "#ff0000",
  moderate: "#ffaa00",
  good: "#0d8400",
  excellent: "#0d8400",
};

export const RADAR_COLORS: RadarColors = {
  green: {
    excellent: "#a5dac4",
    good: "#74c8ac",
    moderate: "#2bbe8d",
    poor: "#00aa76",
    very_poor: "#3c6656",
  },
  blue: {
    excellent: "#b6dafd",
    good: "#70b4f7",
    moderate: "#286fb6",
    poor: "#0e4174",
    very_poor: "#0b335b",
  },
  purple: {
    excellent: "#d1b6d2",
    good: "#bdc8d8",
    moderate: "#93a9c6",
    poor: "#617793",
    very_poor: "#4d5f75",
  },
  black: {
    excellent: "#ebebeb",
    good: "#c5c5c5",
    moderate: "#9e9e9e",
    poor: "#5e5e5e",
    very_poor: "#383838",
  },
};

export const APP_CUSTOMIZATION: AppCustomization = {
  "dermobella-skin": {
    logo: LogoDermobella,
    color: "#4d9f87",
    radarColors: RADAR_COLORS.green,
    measurements: {
      pores: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      spots: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      wrinkles: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      porphyrin: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      keratin: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      sensitivityredness: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
    },
    visibleBars: [
      "pores",
      "spots",
      "porphyrin",
      "wrinkles",
      "keratin",
      "sensitivityredness",
    ],
    visibleRadars: [
      "pores",
      "keratin",
      "wrinkles",
      "porphyrin",
      "spots",
      "sensitivityredness",
    ],
  },
  "dermopico-skin": {
    logo: LogoDermopico,
    color: "#5dd3b7",
    radarColors: RADAR_COLORS.green,
    measurements: {
      impurity: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      pores: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      wrinkles: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      moistureT: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
      moistureU: {
        excellent: [0, 5],
        good: [6, 15],
        moderate: [16, 48],
        poor: [49, 80],
        very_poor: [81, 100],
      },
    },
    visibleBars: ["wrinkles", "pores", "impurity"],
    visibleRadars: ["moistureT", "wrinkles", "impurity", "pores", "moistureU"],
  },
  "dermobella-hair": {
    logo: LogoDermobella,
    color: "#055e98",
    radarColors: RADAR_COLORS.blue,
    measurements: {
      density: {
        excellent: [71, 100],
        good: [51, 70],
        moderate: [26, 50],
        poor: [11, 25],
        very_poor: [0, 10],
      },
      hairloss: {
        excellent: [70, 100],
        good: [39, 69],
        moderate: [27, 38],
        poor: [14, 26],
        very_poor: [0, 13],
      },
      scalpKeratin: {
        excellent: [0, 14],
        good: [15, 30],
        moderate: [31, 50],
        poor: [51, 70],
        very_poor: [71, 100],
      },
      scalpredness: {
        excellent: [0, 14],
        good: [15, 30],
        moderate: [31, 50],
        poor: [51, 70],
        very_poor: [71, 100],
      },
    },
    visibleBars: [
      "hairloss",
      "density",
      "scalpKeratin",
      "scalpredness",
      "thickness",
    ],
    visibleRadars: [
      "hairloss",
      "thickness",
      "scalpredness",
      "scalpKeratin",
      "density",
    ],
  },
  "dermopico-hair": {
    logo: LogoDermopico,
    color: "#6fb6fc",
    radarColors: RADAR_COLORS.blue,
    measurements: {
      density: {
        excellent: [81, 100],
        good: [51, 80],
        moderate: [31, 50],
        poor: [21, 30],
        very_poor: [0, 20],
      },
      hydration: BAR_RATES_REVERSED,
      impurity: {
        excellent: [0, 10],
        good: [11, 20],
        moderate: [21, 40],
        poor: [41, 70],
        very_poor: [71, 100],
      },
      keratin: {
        excellent: [0, 20],
        good: [21, 30],
        moderate: [31, 50],
        poor: [51, 80],
        very_poor: [81, 100],
      },
      sebum: {
        excellent: [71, 100],
        good: [41, 70],
        moderate: [21, 40],
        poor: [11, 20],
        very_poor: [0, 10],
      },
    },
    visibleBars: ["hydration", "density", "keratin", "impurity"],
    visibleRadars: ["hydration", "density", "keratin", "impurity"],
  },
  "pierre-fabre-skin": {
    logo: LogoPirreFabre,
    color: "#3c6656",
    radarColors: RADAR_COLORS.green,
    measurements: {
      pores: {
        excellent: [0, 14],
        good: [15, 20],
        moderate: [21, 27],
        poor: [28, 32],
        very_poor: [33, 100],
      },
      spots: {
        excellent: [0, 12],
        good: [13, 17],
        moderate: [18, 26],
        poor: [27, 40],
        very_poor: [41, 100],
      },
      wrinkles: {
        excellent: [0, 26],
        good: [27, 31],
        moderate: [32, 38],
        poor: [39, 45],
        very_poor: [46, 100],
      },
      porphyrin: {
        excellent: [0, 3],
        good: [4, 7],
        moderate: [8, 15],
        poor: [16, 23],
        very_poor: [24, 100],
      },
      keratin: {
        excellent: [0, 8],
        good: [9, 13],
        moderate: [14, 19],
        poor: [20, 22],
        very_poor: [23, 100],
      },
      sensitivityredness: {
        excellent: [0, 15],
        good: [16, 25],
        moderate: [26, 40],
        poor: [41, 70],
        very_poor: [71, 100],
      },
    },
    visibleBars: [
      "pores",
      "spots",
      "porphyrin",
      "wrinkles",
      "keratin",
      "sensitivityredness",
    ],
    visibleRadars: [
      "pores",
      "keratin",
      "wrinkles",
      "porphyrin",
      "spots",
      "sensitivityredness",
    ],
  },
  "pierre-fabre-hair": {
    logo: LogoPirreFabre,
    color: "#3c6656",
    radarColors: RADAR_COLORS.green,
    measurements: {
      density: {
        excellent: [71, 100],
        good: [51, 70],
        moderate: [26, 50],
        poor: [11, 25],
        very_poor: [0, 10],
      },
      hairloss: {
        excellent: [81, 100],
        good: [61, 80],
        moderate: [41, 60],
        poor: [21, 40],
        very_poor: [0, 20],
      },
      scalpKeratin: {
        excellent: [0, 14],
        good: [15, 30],
        moderate: [31, 50],
        poor: [51, 70],
        very_poor: [71, 100],
      },
      scalpredness: {
        excellent: [0, 14],
        good: [15, 30],
        moderate: [31, 50],
        poor: [51, 70],
        very_poor: [71, 100],
      },
      thickness: {
        excellent: [81, 100],
        good: [61, 80],
        moderate: [41, 60],
        poor: [21, 40],
        very_poor: [0, 20],
      },
    },
    visibleBars: [
      "hairloss",
      "density",
      "scalpKeratin",
      "scalpredness",
      "thickness",
    ],
    visibleRadars: [
      "hairloss",
      "scalpredness",
      "thickness",
      "scalpKeratin",
      "density",
    ],
  },
  "morgan-hair": {
    logo: LogoMorgan,
    color: "#afc1d2",
    radarColors: RADAR_COLORS.purple,
    measurements: {
      density: {
        excellent: [71, 100],
        good: [51, 70],
        moderate: [26, 50],
        poor: [11, 25],
        very_poor: [0, 10],
      },
      hairloss: {
        excellent: [62, 100],
        good: [39, 61],
        moderate: [27, 38],
        poor: [14, 26],
        very_poor: [0, 13],
      },
      scalpKeratin: {
        excellent: [0, 14],
        good: [15, 30],
        moderate: [31, 50],
        poor: [51, 70],
        very_poor: [71, 100],
      },
      scalpRedness: {
        excellent: [0, 14],
        good: [15, 30],
        moderate: [31, 50],
        poor: [51, 70],
        very_poor: [71, 100],
      },
    },
    visibleBars: [
      "hairloss",
      "density",
      "scalpKeratin",
      "scalpRedness",
      "thickness",
    ],
    visibleRadars: [
      "hairloss",
      "thickness",
      "scalpRedness",
      "scalpKeratin",
      "density",
    ],
  },
  "loreal-hair": {
    logo: LogoLoreal,
    color: "#383838",
    radarColors: RADAR_COLORS.black,
    measurements: {
      density: BAR_RATES_REVERSED,
      hydration: {
        very_poor: [0, 5],
        poor: [6, 15],
        moderate: [16, 48],
        good: [49, 80],
        excellent: [81, 100],
      },
      impurity: BAR_RATES,
      keratin: BAR_RATES,
      sebum: {
        excellent: [71, 100],
        good: [41, 70],
        moderate: [21, 40],
        poor: [11, 20],
        very_poor: [0, 10],
      },
    },
    visibleBars: ["hydration", "density", "keratin", "impurity"],
    visibleRadars: ["hydration", "density", "keratin", "impurity"],
  },
};
