import axios from "axios";
import {
  ClientResponseData,
  DermobellaResponse,
  DermopicoResponse,
  CustomizationResponse,
} from "types/api";
import { AppData } from "types/app";
import { formatDate } from "lib/formatDate";
import { getApiUrls } from "./getApiUrls";
import { formatResults } from "lib/formatResults";
import { getVisibleResults } from "lib/getVisibleResults";

export const fetchAppData = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const batchId = urlParams.get("batch_id") || "";
  const customerId = urlParams.get("customer_id") || "";
  const consultant_company_id = urlParams.get("consultant_company_id") || "";
  const isNewCrm = urlParams.get("crm_version") === "3";

  let lang = urlParams.get("lang") || "";
  if (lang === "zhhant") lang = "zh-Hant";
  if (lang === "zhhans") lang = "zh-Hans";

  const { GET_TOKEN_URL, GET_CUSTOMER_URL, GET_RESULTS_URL, appName } =
    getApiUrls(isNewCrm);

  const {
    data: { token },
  } = await axios.post<{ token: string }>(GET_TOKEN_URL);

  const customerUrl = `${GET_CUSTOMER_URL}/${customerId}`;
  const headers = { "X-CHOWIS-TOKEN": token };

  let userData = {} as any;
  try {
    const { data } = await axios.get<ClientResponseData>(customerUrl, {
      headers,
    });
    userData = data;
  } catch (err) {
    console.error(err);
  }

  const resultsUrl = `${GET_RESULTS_URL}/${batchId}`;
  const { data } = await axios.get<DermobellaResponse | DermopicoResponse>(
    resultsUrl
  );
  const results = "body" in data ? data.body : data.data;

  const formatedResults = formatResults({ results, appName });
  const { barGraphs, radars } = getVisibleResults({
    results: formatedResults,
    appName,
  });

  let time = "";
  let date = "";

  if (results.length) {
    time = formatDate(results[0].date);
    date = time.split(".")?.shift() || "";
  }

  const gender =
    userData?.gender === 1 ? "male" : userData?.gender === 2 ? "female" : "";

  let customization = {};

  if (consultant_company_id) {
    try {
      const { data: customizationData = {} } =
        await axios.get<CustomizationResponse>(
          `${GET_CUSTOMER_URL}/api/consultants/consult-company-details.json?consultant_company_id=${consultant_company_id}`
        );
      customization = customizationData;
    } catch (err) {
      console.error(err);
    }
  }

  return {
    ...userData,
    gender,
    time,
    date,
    results: formatedResults,
    customization,
    barGraphs,
    radars,
    lang,
    appName,
    consultant_company_id,
  } as AppData;
};
