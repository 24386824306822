import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

export const RadarChartContainer = styled.section`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media ${breakpoints.mobile} {
    min-height: auto;
  }
`;

export const RadarContainer = styled.div`
  margin-top: 25px;
  position: relative;
  width: 375px;
  height: 375px;

  @media ${breakpoints.mobile} {
    width: calc(375px / 2);
    height: calc(375px / 2);
    margin-top: 0;
  }
`;

export const Circle = styled.div<{ width: number; background?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.width}px`};
  border-radius: 100%;
  background: ${(props) => props.background};
  border: 1px solid white;

  @media ${breakpoints.mobile} {
    width: ${(props) => `${props.width / 2}px`};
    height: ${(props) => `${props.width / 2}px`};
  }
`;

export const Divider = styled.div<{ rotateDegree?: number }>`
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: transparent;
  transform-origin: bottom right;
  border-right: 1px solid white;
  transform: ${(props) => `rotate(${props.rotateDegree}deg)`};
`;

export const Title = styled.span<{
  rotateDegree?: number;
  rate: string;
}>`
  position: absolute;
  width: 130px;
  // 'Pores' and 'Impurities' titles exception
  top: ${(props) =>
    props.rotateDegree === 0 || props.rotateDegree === -180
      ? "-50px"
      : "-85px"};
  right: -65px;
  color: ${(props) =>
    props.rate === "very_poor" ||
    props.rate === "very_dehydrated" ||
    props.rate === "dehydrated" ||
    props.rate === "poor"
      ? "red"
      : "black"};
  font-size: 16px;
  text-align: center;
  word-wrap: break-word;
  transform: ${(props) => `rotate(${props.rotateDegree}deg)`};

  @media ${breakpoints.tablet} {
    max-width: 100px;
    top: ${(props) =>
      props.rotateDegree === 0 || props.rotateDegree === -180
        ? "-30px"
        : "-50px"};
    right: -35px;
    font-size: 14px;
  }

  @media ${breakpoints.mobile} {
    max-width: 70px;
  }
`;

export const Dot = styled.div<{ position?: number }>`
  position: absolute;
  right: -5.5px;
  bottom: ${(props) => `${props.position}%`};
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #eeca30;

  @media ${breakpoints.mobile} {
    width: calc(10px / 1.5);
    height: calc(10px / 1.5);
    right: -4px;
  }
`;

export const RatesList = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RateColor = styled.div<{ background?: string }>`
  width: 20px;
  height: 20px;
  background: ${(props) => props.background};

  @media ${breakpoints.mobile} {
    width: calc(20px / 1.5);
    height: calc(20px / 1.5);
  }
`;

export const RateDescription = styled.span`
  font-size: 18px;

  @media ${breakpoints.mobile} {
    font-size: 14px;
  }
`;
